<template>
    <div class="goods_sort">
        <div class="boxbg" style="z-index:2; position: relative;">
            <div class="class_ification_wrap clearfix">
                <!-- 一级分类 -->
                <ul :class="{fl:true,class_ification:true,fixed:isTop, bottomFixed:isBottom}">
                    <li v-for="(item,index) in navList.data" :key="index"
                        :class="index === indexNum ? 'on cat_item' : 'cat_item' ">
                        <div @click="toTop(index)">{{item.categoryName}}</div>
                    </li>
                </ul>
                <ul class="class_detail">
                    <li class="detail_item" v-for="(item1,index) in navList.data" :key="index">
                        <div class="detail_title">{{item1.categoryName}}</div>
                        <div class="cat_wrap_dd">
                            <div class="cat_dd_item" v-for="(item2,cateIndex) in item1.children" :key="cateIndex">
                                <div class="fl cat_left" :style="{color:color,cursor:'pointer'}"
                                    @click="toGoodsList(item2.categoryId)">
                                    {{item2.categoryName}} >
                                </div>
                                <div class="cat_add_right">
                                    <span v-for="(item3,index) in item2.children" @click="toGoodsList(item3.categoryId)"
                                        style="cursor:pointer;" :key="index">{{item3.categoryName}}</span>
                                </div>
                            </div>
                            <div class="detail_none flex_row_center_center" v-if="!item1.children&&!firstLoading">
                                <img :src="noneUrl" style="width: 100px;margin-bottom: 15px;">
                                <p>{{L['暂无数据']}}</p>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCurrentInstance, reactive, ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router'

    export default {
        name: "GoodsCategory",
        components: {},
        setup() {
            const firstLoading = ref(true);//是否第一次加载
            const noneUrl = require('@/assets/common_empty.png');
            let indexNum = ref(0);
            const { proxy } = getCurrentInstance();
            const router = useRouter();
            const L = proxy.$getCurLanguage();
            const navList = reactive({ data: {} });
            const isTop = ref(false)
            const isBottom = ref(false)
            const getInitData = (index, params) => {
                proxy.$get('/v3/goods/front/goods/category/list', params).then(res => {
                    firstLoading.value = false;
                    if (res.state == 200) {

                        navList.data = res.data.list;
                    }
                });
            }
            const selectTab = (index, categoryId) => {
                indexNum.value = index;

                let params = {
                    categoryId1: categoryId
                }
                getInitData(index, params);
            }

            const toGoodsList = (categoryId) => {
                let newWin = router.resolve({
                    path: 'list',
                    query: {
                        categoryId: categoryId
                    }
                })
                window.open(newWin.href, '_blank')
            }

            const handleScroll = () => {
                var height = 150;
                var scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (scrollTop > height) {
                    isTop.value = true;
                    isBottom.value = false
                } else {
                    isTop.value = false;
                }

                let bottom = document.getElementsByClassName('footer_service_wrap')[0].offsetTop
                let ul = document.getElementsByClassName('class_ification')[0].offsetHeight
                if (ul + scrollTop + 45 >= bottom) {
                    isTop.value = false;
                    isBottom.value = true
                }
            }
            window.addEventListener('scroll', handleScroll)

            const toTop = (index) => {
                indexNum.value = index;
                let ul = document.getElementsByClassName('class_detail')[0]
                let li = ul.getElementsByTagName('li')[index].offsetTop
                window.scrollTo({
                    top: li + 115,
                    behavior: 'smooth'
                })
            }

            window.onscroll = function () {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                let ul = document.getElementsByClassName('class_detail')[0]
                let li = Array.from(ul.getElementsByTagName('li'))
                li.forEach((item, i) => {
                    if (scrollTop >= li[i].offsetTop) {
                        indexNum.value = i
                    }
                });
            }

            onMounted(() => {

                getInitData(0, {});
            })
            return { navList, selectTab, noneUrl, indexNum, toGoodsList, L, firstLoading, isTop, toTop, isBottom };
        },
    };

</script>

<style lang="scss" scoped>
    @import "@/style/category.scss";
    @import "@/style/base.scss";

    .detail_none {
        flex-direction: column;
        margin-top: 7px;
        width: inherit;
        height: 200px;
    }

    .detail_none p {
        padding: 7px;
    }
</style>