<template>
  <div class="sld_login">
    <div class="sld_login_header">
      <div class="content">
        <router-link tag="a" class="l_logo" :to="`/index`">
          <img class="img" :src="configInfo.main_site_logo" :onerror="defaultImg" alt />
        </router-link>
        <div class="r_register_wrap">
          {{L['还没注册？']}}
          <a class="go_register_btn" href="javascript:void(0)" @click="goToPage('/register')">{{L['去注册']}}</a>
        </div>
      </div>
    </div>
    <div class="sld_login_content">
      <img class="bg" :src="configInfo.main_user_logon_bg" :onerror="defaultBgImg" alt />
      <div class="login">
        <div class="login_box">
          <div class="top">
            <div :class="{item:true,active:loginType == 1}" @click="changeLoginType(1)">{{L['账号登录']}}</div>
            <div :class="{item:true,active:loginType == 2}" @click="changeLoginType(2)">{{L['手机登录']}}</div>
          </div>
          <div class="center">
            <template v-if="loginType == 1">
              <div class="item account">
                <span style="color: #BBB;font-size: 19px;padding-top: 7px" class="icon iconfont icon-wode"></span>
                <input type="text" v-model="name" :placeholder="L['请输入账号/手机号']" class="input" />
                <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                  <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                </div>
              </div>
              <div class="item password">
                <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon iconfont icon-mima1"></span>
                <input type="password" v-model="password" :placeholder="L['请输入密码']" class="input" />
                <div data-type="password" class="cancel" @click="clearInputVal('password')">
                  <span style="color: #bbb" class="iconfont icon-cuowu"></span>
                </div>
              </div>
            </template>
            <template v-if="loginType == 2">
              <div class="item account">
                <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon iconfont icon-shouji2"></span>
                <input type="text" v-model="name" :placeholder="L['请输入手机号']" class="input" />
                <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                  <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                </div>
              </div>
              <div class="item password">
                <span style="color: #BBB;font-size: 18px;padding-top: 8px"
                  class="icon iconfont icon-yanzhengma2"></span>
                <input type="text" v-model="password" :placeholder="L['请输入验证码']" class="input" />
                <a href="javascript:void(0);" class="send_code"
                  @click="getSmsCode">{{countDownM?(countDownM+L['s后获取']):L['获取验证码']}}</a>
              </div>
            </template>
            <div class="error">
              <span v-if="errorMsg" style="color: #e1251b;font-size: 14px" class="iconfont icon-jubao"></span>
              {{errorMsg}}
            </div>
            <a href="javascript:void(0)" @click="login" class="login_btn">{{L['登录']}}</a>
          </div>
          <div class="bottom flex_row_end_center">
            <a href="javascript:void(0)" @click="goToPage('/register')">{{L['立即注册']}}</a>
            <router-link tag="a" :to="`/pwd/forget`">{{L['忘记密码']}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter, onBeforeRouteLeave } from "vue-router";
  import { ref, getCurrentInstance } from "vue";
  import { useStore } from "vuex";

  export default {
    name: "LoginIndex",
    setup() {
      const store = useStore();
      const keyEnter = ref(true)
      const name = ref("13522940589"); //用户名
      const errorMsg = ref(); //错误提示
      const password = ref("111111"); //密码
      const loginType = ref(1); //登陆类型：1-账号密码登陆，2-手机验证码登陆
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const countDownM = ref(0); //短信验证码倒计时
      const timeOutId = ref(""); //定时器的返回值
      const configInfo = ref(store.state.configInfo);
      const defaultImg = ref(
        'this.src="' + require("../../../assets/common_top_logo.png") + '"'
      );
      const defaultBgImg = ref(
        'this.src="' + require("../../../assets/login_bg.png") + '"'
      );

      //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
      document.onkeydown = function () {
        var key = window.event.keyCode;
        if (key == 13 && keyEnter.value) {
          login();
        }
      };
      const login = () => {
        let param = {};
        param.username = name.value;
        param.password = password.value;
        param.loginType = loginType.value;
        if (param.loginType == 1) {
          //账号、手机号的验证
          if (!param.username) {
            errorMsg.value = L["请输入账号/手机号"];
            return false;
          } else {
            let checkName = proxy.$checkMemberName(param.username);
            if (checkName !== true) {
              //继续验证是否是手机号
              let checkMobile = proxy.$checkMobile(param.username);
              if (checkMobile !== true) {
                errorMsg.value = L["请输入正确的账号/手机号"];
                return false;
              }
            }
          }

          //密码校验
          if (!param.password) {
            errorMsg.value = L["请输入密码"];
            return false;
          } else {
            let checkPwd = proxy.$checkPwd(param.password);
            if (checkPwd !== true) {
              errorMsg.value = checkPwd;
              return false;
            }
          }
        } else {
          //手机号验证
          if (!param.username) {
            errorMsg.value = L["请输入手机号"];
            return false;
          } else {
            let checkMobile = proxy.$checkMobile(param.username);
            if (checkMobile !== true) {
              errorMsg.value = checkMobile;
              return false;
            }
          }

          //验证码校验
          if (!param.password) {
            errorMsg.value = L["请输入短信验证码"];
            return false;
          } else {
            let checkSmsCode = proxy.$checkSmsCode(param.password);
            if (checkSmsCode !== true) {
              errorMsg.value = checkSmsCode;
              return false;
            }
          }
        }
        //如果未登录vuex中有购物车数据，需同步到该用户
        let cartInfo = [];
        let cartListData = store.state.cartListData;
        if (JSON.stringify(cartListData) != "{}") {
          cartListData.storeCartGroupList.map(cartListItem => {
            cartListItem.promotionCartGroupList.map(promotItem => {
              promotItem.cartList.map(cartItem => {
                cartInfo.push({
                  productId: cartItem.productId,
                  buyNum: cartItem.buyNum
                });
              });
            });
          });
        }
        param.cartInfo = JSON.stringify(cartInfo);
        proxy.$post("v3/frontLogin/oauth/token", param).then(res => {
          if (res.state == 200) {
            //将用户信息存缓存，并跳转到首页
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            //获取用户信息，并同步信息到vuex
            proxy.$get("/v3/member/front/member/getInfo").then(res => {
              if (res.state == 200) {
                store.commit("updateMemberInfo", res.data);
                proxy.$getLoginCartListData(); //更新购物车数据
                router.back();
              }
            });
            countDownM.value = 60;
            countDown();
          } else {
            //提示错误
            errorMsg.value = res.msg;
          }
        });
      };
      //清空输入框内容
      const clearInputVal = type => {
        if (type == "name") {
          name.value = "";
        } else if (type == "password") {
          password.value = "";
        }
      };
      //登录方式切换
      const changeLoginType = type => {
        loginType.value = type;
        name.value = "";
        password.value = "";
        errorMsg.value = "";
      };
      //获取短信验证码
      const getSmsCode = () => {
        if (countDownM.value) {
          return false;
        }
        let checkMobile = proxy.$checkMobile(name.value);
        if (checkMobile !== true) {
          errorMsg.value = checkMobile;
        } else {
          let param = {};
          param.mobile = name.value;
          param.type = "free";
          proxy.$get("v3/msg/front/commons/smsCode", param).then(res => {
            if (res.state == 200) {
              countDownM.value = 10;
              countDown();
            } else {
              //提示错误
              errorMsg.value = res.msg;
            }
          });
        }
      };
      //倒计时
      const countDown = () => {
        countDownM.value--;
        if (countDownM.value == 0) {
          clearTimeout(timeOutId.value);
        } else {
          timeOutId.value = setTimeout(countDown, 1000);
        }
      };
      //通过replace方式跳转页面
      const goToPage = type => {
        router.replace({
          path: type
        });
      };

      //在离开时将keyEnter置为false,进入的下一个页面则不触发登录的回车键事件
      onBeforeRouteLeave(() => {
        keyEnter.value = false
      })

      return {
        L,
        loginType,
        login,
        name,
        password,
        clearInputVal,
        errorMsg,
        changeLoginType,
        getSmsCode,
        countDownM,
        goToPage,
        configInfo,
        defaultImg,
        defaultBgImg
      };
    }
  };
</script>
<style lang="scss" scoped>
  @import "../../../style/login.scss";
</style>